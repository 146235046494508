var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.totalRecords > 0 || (_vm.items && _vm.items.length > 0)
    ? _c("b-pagination", {
        staticClass: "my-2 border-none",
        attrs: {
          "total-rows": _vm.totalRecords || _vm.items.length,
          "current-page": _vm.currentPage,
          "per-page": _vm.perPage,
          align: "fill",
          "hide-ellipsis": true,
          "hide-goto-end-buttons": true,
          "aria-controls": "nothing",
        },
        on: {
          "update:currentPage": function ($event) {
            _vm.currentPage = $event
          },
          "update:current-page": function ($event) {
            _vm.currentPage = $event
          },
          input: _vm.onInput,
        },
        model: {
          value: _vm.cPage,
          callback: function ($$v) {
            _vm.cPage = $$v
          },
          expression: "cPage",
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }