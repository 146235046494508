<template>
  <div>
    <div class="pdf-viewer-container">
      <div class="pdf-viewer">
        <img :src="pageUrl">

        <div v-for="rf in fields" :key="rf.id" class="pdf-field" :style="styleForField(rf)" @click="fieldClicked(rf)">
          <img v-if="signatureField(rf) && formData[rf.id]" :ref="rf.id" :src="formData[rf.id]">
          <schema-form-date-field
            v-else-if="rf.field_type === 'date'"
            :value="formData[rf.id]"
            :field="rf"
          />
          <input v-else :ref="rf.id" v-model="formData[rf.id]" :type="rf.field_type">
        </div>
      </div>
    </div>

    <c-pagination
      :key="paginationKey"
      :items="pages"
      :current-page.sync="currentPage"
      :per-page="1"
      @update:current-page="$emit('update:current-page')"
    />

    <b-button variant="outline-primary" class="float-right mt-sm-2" @click="download()">
      Download PDF
    </b-button>
  </div>
</template>

<script>
import CPagination from './shared/Pagination'
import axiosClient from '../http'
import SchemaFormDateField from '@/components/SchemaForm/fields/SchemaFormDateField'

export default {
  name: 'V1ResourcePdfViewer',
  components: { CPagination, SchemaFormDateField },
  props: {
    resource: Object,
    companyId: String,
  },
  data() {
    return {
      currentPage: 1,
      formData: {},
      paginationKey: 0,
    }
  },

  computed: {
    pageUrl() {
      return this.resource.page_urls[this.currentPage - 1]
    },

    pages() {
      return this.resource.pages
    },

    fields() {
      return this.resource.fields.filter(x => x.page === this.currentPage - 1)
    },
  },
  async mounted() {
    const response = await axiosClient.get(`client/companies/${this.companyId}/fields_for/${this.resource.id}`)

    const fields = response.data.result ? response.data.result.resource_field_values : {}

    this.formData = Object.assign({}, fields)
    this.$emit('loaded')
  },

  methods: {
    styleForField(rf) {
      return {
        top: `${rf.y * 100}%`,
        left: `${rf.x * 100}%`,
        width: `${rf.width * 100}%`,
        height: `${rf.height * 100}%`,
      }
    },
    async download() {
      const response = await axiosClient.post(`resources/${this.resource.id}/fill_fields`,
        { form_data: this.formData },
        { responseType: 'blob' },
      )

      const url = URL.createObjectURL(response.data)

      const a = document.createElement('a')
      a.style = 'display: none'
      a.href = url
      a.target = '_blank'
      a.download = 'filing.pdf'

      document.body.appendChild(a)

      a.click()

      setTimeout(() => {
        URL.revokeObjectURL(url)
        document.body.removeChild(a)
      }, 1000)
    },
    fieldClicked(rf) {
      this.$emit('clicked', rf)
    },
    signatureField(field) {
      return field?.data?.parts?.includes('signature') || field.field_type === 'signature'
    },
  },
}
</script>

<style scoped>
.pdf-viewer {
  position: relative;
}

.pdf-viewer-container {
  height: 600px;
  overflow-y: scroll;
}

.pdf-viewer img {
  width: 100%;
}

.pdf-field {
  position: absolute;
}

.pdf-field input {
  position: absolute;
  width: 100%;
  height: 90%;
  border: none;
  background-color: #dde4ff;
  border: 1px transparent;
  bottom: 0;
  padding: 0 4px;
  border-radius: 3px;
}

.pdf-field img {
  position: absolute;
  height: 100%;
  width: auto;
}
</style>
