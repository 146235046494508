var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "pdf-viewer-container" }, [
        _c(
          "div",
          { staticClass: "pdf-viewer" },
          [
            _c("img", { attrs: { src: _vm.pageUrl } }),
            _vm._l(_vm.fields, function (rf) {
              return _c(
                "div",
                {
                  key: rf.id,
                  staticClass: "pdf-field",
                  style: _vm.styleForField(rf),
                  on: {
                    click: function ($event) {
                      return _vm.fieldClicked(rf)
                    },
                  },
                },
                [
                  _vm.signatureField(rf) && _vm.formData[rf.id]
                    ? _c("img", {
                        ref: rf.id,
                        refInFor: true,
                        attrs: { src: _vm.formData[rf.id] },
                      })
                    : rf.field_type === "date"
                    ? _c("schema-form-date-field", {
                        attrs: { value: _vm.formData[rf.id], field: rf },
                      })
                    : rf.field_type === "checkbox"
                    ? _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formData[rf.id],
                            expression: "formData[rf.id]",
                          },
                        ],
                        ref: rf.id,
                        refInFor: true,
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(_vm.formData[rf.id])
                            ? _vm._i(_vm.formData[rf.id], null) > -1
                            : _vm.formData[rf.id],
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.formData[rf.id],
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.formData,
                                    rf.id,
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.formData,
                                    rf.id,
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.formData, rf.id, $$c)
                            }
                          },
                        },
                      })
                    : rf.field_type === "radio"
                    ? _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formData[rf.id],
                            expression: "formData[rf.id]",
                          },
                        ],
                        ref: rf.id,
                        refInFor: true,
                        attrs: { type: "radio" },
                        domProps: {
                          checked: _vm._q(_vm.formData[rf.id], null),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$set(_vm.formData, rf.id, null)
                          },
                        },
                      })
                    : _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formData[rf.id],
                            expression: "formData[rf.id]",
                          },
                        ],
                        ref: rf.id,
                        refInFor: true,
                        attrs: { type: rf.field_type },
                        domProps: { value: _vm.formData[rf.id] },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.formData, rf.id, $event.target.value)
                          },
                        },
                      }),
                ],
                1
              )
            }),
          ],
          2
        ),
      ]),
      _c("c-pagination", {
        key: _vm.paginationKey,
        attrs: {
          items: _vm.pages,
          "current-page": _vm.currentPage,
          "per-page": 1,
        },
        on: {
          "update:currentPage": function ($event) {
            _vm.currentPage = $event
          },
          "update:current-page": [
            function ($event) {
              _vm.currentPage = $event
            },
            function ($event) {
              return _vm.$emit("update:current-page")
            },
          ],
        },
      }),
      _c(
        "b-button",
        {
          staticClass: "float-right mt-sm-2",
          attrs: { variant: "outline-primary" },
          on: {
            click: function ($event) {
              return _vm.download()
            },
          },
        },
        [_vm._v("\n    Download PDF\n  ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }